<!-- <div class="page-wraper"> -->
  <div class="section-full ">

    <div class="container">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->
 <!-- TITLE START-->
 <br>
 <br>

 <div class="section-head center wt-small-separator-outer text-center">
  <div class="wt-small-separator site-text-primary">
      <div  class="sep-leaf-left"></div>
      <div>{{data.title}}</div>
      <div  class="sep-leaf-right"></div>
  </div>
  <h2>{{data.subtitle}}</h2>
</div>
<!-- TITLE END-->

<div class="section-content hover-block-outer">
      <div class="row d-flex justify-content-center">

          <div class="col-md-7 col-sm-12 m-b30 top-bottom-animation ">
              <div class="Safe-mid-section">
                  <div class="wt-box">
                      <div class="wt-media text-primary text-center">
                        <iframe
                                    width="400px" height="500px" frameBorder="0" allowfullscreen allow-same-origin allow-scripts
                                    src="https://dominicanplayers.com/video-player/1707/0">
                        </iframe>

                          <!-- <img src="{{data.image}}" alt="" class="img-top-bottom-aanimation"> -->


                      </div>
                  </div>
               </div>
          </div>

          <div class="col-md-5 col-sm-12   ">
              <div class="wt-icon-box-wraper left " *ngFor="let object of data.specifications">
                  <div class="icon-xl text-primary">
                      <span class="icon-cell"><img src="{{object.image}}" alt=""></span>
                  </div>
                  <div class="icon-content">
                      <h4 class="wt-tilte text-uppercase">{{object.title}}</h4>
                      <!-- <p>{{object.description}} </p> -->
                      <audio  controls src="{{object.audio}}"> </audio>
                  </div>
              </div>
          </div>

      </div>
</div>

    </div>
</div>

<app-loader></app-loader>
