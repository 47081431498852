import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-blog-grid',
  templateUrl: './page-blog-grid.component.html',
  styleUrls: ['./page-blog-grid.component.css']
})
export class PageBlogGridComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/bg-self500.png",
    title: "Entrevistas",
    crumb: "entrevistas"
  }

  blogs = [
    {
      image: "assets/images/entrevistas/rubby-perez.png",
      // field: "Materials",
      // date: "July 28, 2021",
      // comments: "24",
      url: "https://www.youtube.com/watch?v=8lEGSIAU-bA",
      title: "Entrevista a Rubby Perez"
    },
    {
      image: "assets/images/entrevistas/frank-reyes.png",
      // field: "Technology",
      // date: "July 28, 2021",
      // comments: "24",
      url:"https://www.youtube.com/watch?v=mapMMXi2NHQ",

      title: "Entrevista a Frank Reyes"
    },
    {
      image: "assets/images/entrevistas/james-brewster.png",
      // field: "Technology",
      // date: "July 28, 2021",
      // comments: "24",
      url:"https://www.youtube.com/watch?v=zSljpeSUJcw",

      title: "Entrevista al Ex Embajador de Rep. Dom., Dr. James Brewster"
    },
    {
      image: "assets/images/entrevistas/arcangel.png",
      // field: "Technology",
      // date: "July 28, 2021",
      // comments: "24",
      url:"https://www.youtube.com/watch?v=X8G2e0qZVok",

      title: "Entrevista a Arcangel La Maravilla"
    },

    {
      image: "assets/images/entrevistas/michael_miguel.png",
      // field: "Technology",
      // date: "July 28, 2021",
      // comments: "24",
      url:"https://www.youtube.com/watch?v=zpCv7uGEOLE",

      title: "Entrevista a Michael Miguel Holguín"
    },
    {
      image: "assets/images/entrevistas/tony-dandrades.png",
      // field: "Manufacturing",
      // date: "July 28, 2021",
      // comments: "24",v
      url:"https://www.youtube.com/watch?v=40IfE90ltEo",

      title: "Entrevista a Tony Dandrades"
    },
    {
      image: "assets/images/entrevistas/Juan-Fernandez.png",
      // field: "Materials",
      // date: "July 28, 2021",
      // comments: "24",
      url:"https://www.youtube.com/watch?v=plSc4RB70IY",

      title: "Entrevista a Juan Fernandez"
    },
    {
      image: "assets/images/entrevistas/luis-miguel-del-amargue.png",
      // field: "Technology",
      // date: "July 28, 2021",
      // comments: "24",
      url:"https://www.youtube.com/watch?v=Pc5E-e-MPxw",

      title: "Entrevista a Luis Miguel del Amargue"
    },
    {
      image: "assets/images/entrevistas/Fherramy.png",
      // field: "Manufacturing",
      // date: "July 28, 2021",
      // comments: "24",
      url:"https://www.youtube.com/watch?v=VeJ0p-dLNIoE",

      title: "Entrevista a Fherramy"
    },
    // {
    //   image: "assets/images/entrevistas/7.jpg",
    //   field: "Materials",
    //   date: "July 28, 2021",
    //   comments: "24",
    //   title: "Technology is important for your business, you use technology makes"
    // },
    // {
    //   image: "assets/images/entrevistas/8.jpg",
    //   field: "Technology",
    //   date: "July 28, 2021",
    //   comments: "24",
    //   title: "Present you with examples of 3 posts from authority websites click"
    // },
    // {
    //   image: "assets/images/entrevistas/9.jpg",
    //   field: "Manufacturing",
    //   date: "July 28, 2021",
    //   comments: "24",
    //   title: "Explain to you why they work and what makes people click them"
    // }
  ]
}
