<div class="page-wraper">

    <!-- HEADER START -->
    <!-- <app-section-header2></app-section-header2> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- WHAT WE DO SECTION START -->
        <app-section-what-we-do6 [data]="whatwedo"></app-section-what-we-do6>
        <!-- WHAT WE DO SECTION END -->

         <!-- ALL SERVICES START -->
        <app-section-services12 [data]="services"></app-section-services12>
        <!-- ALL SERVICES SECTION END -->

        <!-- VIDEO SECTION START -->
        <app-section-video1 [data]="video"></app-section-video1>
        <!-- VIDEO SECTION  SECTION END -->

         <!-- TESTIMONIAL SECTION START -->
         <app-section-testimonials3 [data]="testimonials" whiteBox="0"></app-section-testimonials3>
        <!-- TESTIMONIAL SECTION END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <!-- <app-section-footer1></app-section-footer1> -->
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
