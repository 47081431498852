<div class="page-wraper">


    <!-- HEADER START -->
    <!-- <app-section-header2></app-section-header2> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- SECTION CONTENT START -->
        <div class="section-full p-t80 p-b80">

            <!-- PRODUCT DETAILS -->
            <div class="container woo-entry">

                <!-- SECTION CONTENT START -->
                    <div class="section-content">
                        <div class="row">
                            <!-- FROM STYEL 1 WITH ICON -->
                            <div class="col-lg-4 col-md-12 m-b30">

                                <div class="left wt-small-separator-outer">
                                    <h2>Your Order</h2>
                                </div>


                                <div class="wt-box your-order-list bdr-4 bdr-gray-light bdr-solid p-a30 site-bg-secondry">
                                    <ul>
                                        <li>Item One<strong class="pull-right site-text-primary">$15.00</strong></li>
                                        <li>Item Two<strong class="pull-right site-text-primary">$150.00</strong></li>
                                        <li><b> Cart Subtotal</b><strong class="pull-right site-text-primary">$165.00</strong></li>
                                        <li><b> Shipping</b><strong class="pull-right site-text-primary">$50.00</strong></li>
                                        <li><b> Cart Total</b><strong class="pull-right site-text-white">$215.00</strong></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- FROM STYEL 1 -->
                            <div class="col-lg-8 col-md-12">

                                <div class="left wt-small-separator-outer">
                                    <h2>Billing Information</h2>
                                </div>

                                <div class="wt-box bdr-4 bdr-gray-light bdr-solid p-a30 bg-gray">
                                    <form>

                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" class="form-control"  placeholder="First Name">
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" class="form-control"  placeholder="Last Name">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label>Company Name</label>
                                            <input type="text" class="form-control" placeholder="Company Name">
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <label>Phone</label>
                                                    <input type="text" class="form-control"  placeholder="Enter Phone Number">
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <label>Email Address</label>
                                                    <input type="email" class="form-control"  placeholder="Enter email">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <label>Country</label>
                                                    <select class="form-control selectpicker">
                                                        <option>Usa</option>
                                                        <option>China</option>
                                                        <option>india</option>
                                                        <option>australia</option>
                                                        <option>japan</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <label>State / City</label>
                                                    <select class="form-control selectpicker">
                                                        <option>Los Angeles</option>
                                                        <option>Chicago</option>
                                                        <option>Phoenix</option>
                                                        <option>San Diego</option>
                                                        <option>Dallas</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <label>Town</label>
                                                    <select class="form-control selectpicker">
                                                        <option>Columbia</option>
                                                        <option>Berkeley</option>
                                                        <option>The Woodlands</option>
                                                        <option>Plano</option>
                                                        <option>Boulder</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <label>EZIP / Post Code</label>
                                                    <input type="text" class="form-control"  placeholder="EZIP / Post Code">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label>Address</label>
                                            <input type="text" class="form-control m-b30"  placeholder="Address 1">
                                            <input type="text" class="form-control"  placeholder="Address 2">
                                        </div>

                                        <div class="form-group form-inline">
                                            <div class="radio">
                                                <input id="checkmeout1" name="Public" value="checkmeout" type="checkbox">
                                                <label for="checkmeout1">Ship to the same address</label>
                                            </div>
                                        </div>

                                        <button type="submit" value="submit"  class="site-button site-btn-effect">Save and Deliver Here</button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- SECTION CONTENT END -->

            </div>
            <!-- PRODUCT DETAILS -->

        </div>
        <!-- CONTENT CONTENT END -->
    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
