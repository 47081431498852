<div class="page-wraper">


    <!-- HEADER START -->
    <!-- <app-section-header2></app-section-header2> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- Error SECTION START -->
        <div class="section-full p-t80 p-b80 bg-gray">
            <div class="container">
                <div class="section-content">

                    <div class="page-notfound error-403">
                        <div class="row">
                            <div class="col-lg-5 col-md-6">
                                <div class="error-303">
                                    <img src="assets/images/error/404.png" alt="">
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <form method="post">
                                <strong class="site-text-primary title-style-2">403</strong>
                                <h4 class="title-style-2">Access Denied/ Forbidden</h4>
                                <p>The page or resource you were trying to reach is absolutely forbidden for some reason.</p>

                                <div class="error-input">

                                  <div class="input-group">
                                    <input id="seaarch" type="text" class="form-control" name="search" placeholder="Search Here...!">
                                    <span class="input-group-addon"><button type="submit" class="error-search-btn">Search</button></span>
                                  </div>

                                </div>

                                    <a routerLink="/index" class="site-button site-btn-effect">GO TO HOME</a>

                            </form>
                           </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Error  SECTION END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
