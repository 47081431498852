import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-pricing',
  templateUrl: './page-pricing.component.html',
  styleUrls: ['./page-pricing.component.css']
})
export class PagePricingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/bg-self500.png",
    title: "Programacion en Vivo",
    crumb: "en Vivo"
  }

  // plans = {
  //   title: "COLOME TV",
  //   subtitle: "Disfruta de la mejor programacion Televisiva y Radial",

  // }

  data = {
    title: "COLOME TV",
    subtitle: "Disfruta de la mejor programacion Televisiva y Radial",
    image: "assets/images/solar-icon/img-1.png",
    specifications: [
      {
        image: "assets/images/background/busqueda13_logoweb.jpg",
        title: "Busqueda 13 Radio",
        audio: "https://radio4.domint.net:8036/stream"
      },
      {
        image: "assets/images/background/logo-radio-cucaracha.jpg",
        title: "Radio Cucaracha",
        audio: "https://radio4.domint.net:8058/stream"
      },
      // {
      //   image: "assets/images/solar-icon/4.png",
      //   title: "Solar Water Heater",
      //   description: "Vitae adipiscing turpis. Aenean ligula nibh, molestie id viverra."
      // }
    ]
  }
}
