import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-project-grid',
  templateUrl: './page-project-grid.component.html',
  styleUrls: ['./page-project-grid.component.css']
})
export class PageProjectGridComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/bg-self500.png",
    title: "Programacion",
    crumb: "programacion"
  }

  projects = [
    {
      category: "cat-1",
      image: "assets/images/programacion/EL_SOL_DE_LA_MANANA.jpg",
      title: "El Sol de la Mañana",
      description: "Lunes a Viernes 7:00 a.m."
    },
    {
      category: "cat-2",
      image: "assets/images/programacion/BEBETO_TV.jpg",
      title: "Bebeto TV",
      description: "Lunes a Viernes de 12:00 p.m. a 3:00 p.m."
    },
    {
      category: "cat-3",
      image: "assets/images/programacion/EL_MISMO_GOLPE.jpg",
      title: "El Mismo Golpe",
      description: "Lunes a Viernes 5:00 p.m."
    },
    {
      category: "cat-4",
      image: "assets/images/programacion/AL_RITMO_DE_LA_NOCHE.jpg",
      title: "Al Ritmo de la Noche",
      description: "Lunes a Viernes 9:00 p.m."
    },
    {
      category: "cat-5",
      image: "assets/images/programacion/EL_SHOW_DE_SILVIO.jpg",
      title: "El Show de Silvio",
      description: "Domingos 12:00 m."
    },
    {
      category: "cat-4",
      image: "assets/images/programacion/COMENTANDO_LA_ACTUALIDAD.jpg",
      title: "Comentando la Actualidad",
      description: "Lunes a Viernes 8:00 p.m."
    },
    {
      category: "cat-3",
      image: "assets/images/programacion/METRO_POR_METRO.jpg",
      title: "Metro por Metro",
      description: "10:00 p.m."
    },
    {
      category: "cat-2",
      image: "assets/images/programacion/80_90_MERENGUES_QUE_HICIERON_HISTORIA.jpg",
      title: "Merengues que hicieron historia",
      description: "Sabados 6:00 p.m."
    },
    {
      category: "cat-1",
      image: "assets/images/programacion/SOMOS_DE_QUISQUELLA.jpg",
      title: "Somos de Quisqueya",
      description: "Domingos 6:00 p.m."
    }
  ]
}
