import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-about2',
  templateUrl: './page-about2.component.html',
  styleUrls: ['./page-about2.component.css']
})
export class PageAbout2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/about.png",
    title: "Nosotros",
    crumb: "nosotros"
  }

  about = {
    title: "Colome TV",
    subtitle: "La Television del Universo",
    image: "assets/images/colarge/s1.png",
    since: "2001",
    description: "Somos un canal de televisión transmitido desde New York dirigido para los  latinoamericanos, con una programación diferente, con un contenido  variado, entretenimiento, entrevista, deportes, reportajes, inmigración,     temas laborales que va de mano con toda la comunidad. Colome TV incluye una amplia variedad de segmentos de     programación que describe la totalidad de distintas culturas y     la hospitalidad de los latinoamericanos.     Transmitido por Colome TV tu Canal Internacional.",
    sign: "",
    authorized: "",
    post: "",
    services: [
      {
        value: "Garantía de Satisfacción 100%"
      },
      {
        value: "La mejor programación"
      },
      {
        value: "Contenido para todo el Universo"
      }
    ]
  }

  vision = {
    title: "Vision & Mission Statement",
    subtitle: "Serving Impressive List Of Long Term Clients!",
    description: "Our worldwide presence ensures timeliness, cost efficiency and compliance adherence required to ensure your timelines are met. Serving with experience aNd expertise in multiple industries, as one of the World's leading Corporation!",
    videoLink: "https://www.youtube.com/watch?v=fgExvIUYg5w",
    quote: "Many of Our SELF registered employees are requested an main preferred temporary staff when all service",
    quoteBy: "Farnandoz Biki, CEO"
  }

  counter = {
    title: "All Solutions",
    subtitle: "Get A Solution For All Transportation",
    bgImage: "assets/images/background/bg-2.jpg",
    opacity: "opacity-09",
    figures: [
      {
        count: "35",
        title: "Projects Completed"
      },
      {
        count: "1435",
        title: "Work Employed"
      },
      {
        count: "750",
        title: "Work facilities"
      },
      {
        count: "26",
        title: "Winning Awards"
      }
    ]
  }

  clients = {
    bgcolor: "bg-gray",
    clients: [
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png"
    ]
  }

  projects = {
    title: "",
    subtitle: "Algunos de Nuestros Servicios",
    bgImage: "assets/images/background/about-bg.jpg",
    projects: [
      {
        category: "cat-1",
        image: "assets/images/project/square/televisiva.png",
        title: "Publicidad Televisiva",
        pagelink: "s-oilgas"
      },
      {
        category: "cat-2",
        image: "assets/images/project/square/reality.jpg",
        title: "Comerciales Televisivos",
        pagelink: "s-automotive"
      },
      {
        category: "cat-3",
        image: "assets/images/project/square/producciones.png",
        title: "Representacion Artistica",
        pagelink: "s-chemical"
      },
      {
        category: "cat-2",
        image: "assets/images/project/square/radial1.jpg",
        title: "Comerciales Radiales",
        pagelink: "s-civil"
      },
      // {
      //   category: "cat-1",
      //   image: "assets/images/project/square/3.jpg",
      //   title: "Menciones publicitarias",
      //   pagelink: "s-civil"
      // },
      // {
      //   category: "cat-4",
      //   image: "assets/images/project/square/4.jpg",
      //   title: "Industrial",
      //   pagelink: "s-mechanical"
      // },
      {
        category: "cat-3",
        image: "assets/images/project/square/entrevista.png",
        title: "Entrevistas",
        pagelink: "s-chemical"
      },
      // {
      //   category: "cat-2",
      //   image: "assets/images/project/square/3.jpg",
      //   title: "Comerciales Redes Sociales",
      //   pagelink: "s-civil"
      // },
      // {
      //   category: "cat-3",
      //   image: "assets/images/project/square/6.jpg",
      //   title: "Promociones de Radio y TV",
      //   pagelink: "s-chemical"
      // },
      // {
      //   category: "cat-5",
      //   image: "assets/images/project/square/5.jpg",
      //   title: "Agriculture",
      //   pagelink: "s-agricultural"
      // },
      {
        category: "cat-1",
        image: "assets/images/project/square/social-networks.png",
        title: "Publicidad en redes sociales",
        pagelink: "s-civil"
      },
      {
        category: "cat-3",
        image: "assets/images/project/square/tv-prod.png",
        title: "Videos Promocionales",
        pagelink: "s-chemical"
      },
      {
        category: "cat-1",
        image: "assets/images/project/square/busqueda13.png",
        title: "Publicidad Radial",
        pagelink: "s-mechanical"
      }
    ]
  }

  selfIntro = {
    image: "assets/images/self-pic.png",
    bgImage: "assets/images/background/bg-self.jpg",
    bgClass: "bg-gray",
    bgCover: "",
    opacity: "opacity-09",
    imageClass: "self-intro-pic-block"
  }
}
