<header class="site-header header-style-2 mobile-sider-drawer-menu">

    <div class="top-bar site-bg-secondry">
        <div class="container">

                <div class="d-flex justify-content-between">
                    <div class="wt-topbar-left d-flex flex-wrap align-content-start">
                        <ul class="wt-topbar-info e-p-bx text-white">
                            <li><span> Programacion</span><span>24/7</span></li>
                        </ul>
                    </div>

                    <div class="wt-topbar-right d-flex flex-wrap align-content-center">
                        <ul class="wt-topbar-info-2 e-p-bx text-white">
                            <li><i class="fa fa-phone"></i>+1 347 636-2126</li>
                            <li><i class="fa fa-envelope"></i>produccionescolome@hotmail.com </li>
                        </ul>

                        <ul class="social-icons">
                            <!-- <li><a href="https://www.google.com/" class="fa fa-google"></a></li> -->
                            <!-- <li><a href="https://twitter.com/" target="_blank" class="fa fa-twitter"></a></li> -->
                            <li><a href="https://www.youtube.com/@ColomeProducciones" target="_blank" class="fa fa-youtube"></a></li>
                            <li><a href="https://www.facebook.com/produccionescolome" target="_blank" class="fa fa-facebook"></a></li>
                            <li><a href="https://www.instagram.com/produccionescolome/" target="_blank" class="fa fa-instagram"></a></li>
                        </ul>

                    </div>
                </div>

        </div>
    </div>

<div class="sticky-header main-bar-wraper  navbar-expand-lg">
        <div class="main-bar">
            <div class="container clearfix">

                    <div class="logo-header">
                        <div class="logo-header-inner logo-header-one">
                            <a routerLink="/index">
                            <img  src="assets/images/logo.png" alt="" />
                            </a>
                        </div>
                    </div>

                    <!-- NAV Toggle Button -->
                    <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggler collapsed">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar icon-bar-first"></span>
                        <span class="icon-bar icon-bar-two"></span>
                        <span class="icon-bar icon-bar-three"></span>
                    </button>

                    <div class="extra-nav header-2-nav">
                        <div class="extra-cell">
                            <!-- <div class="header-search">
                                <a href="javascript:void(0);" class="header-search-icon"><i class="fa fa-search"></i></a>
                            </div> -->
                            <div class="header-nav-request">

                              <!-- <audio  controls
                              src="https://radio4.domint.net:8036/stream">
                              </audio> -->
                                <!-- <a class="contact-slide-show">Request a Quote <i class="fa fa-angle-right"></i></a> -->
                            </div>

                        </div>

                    </div>


                    <!-- MAIN Vav -->
                    <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">

                        <ul class=" nav navbar-nav">
                            <li><a routerLink="/index">Inico</a></li>
                            <li><a routerLink="/about/about2">Nosotros</a></li>
                              <li><a routerLink="/other/biografia">Biografia</a></li>
                            <!-- <li><a routerLink="/services/services2">Servicios</a> -->
                            <li><a routerLink="/projects/project-grid">Programacion</a></li>
                            <li><a routerLink="/blog/blog-grid">Entrevistas</a></li>
                            <li> <a routerLink="/other/vivo">En Vivo</a></li>
                            <li> <a routerLink="/other/contact1">Contactenos</a></li>
                        </ul>

                    </div>

                    <!-- SITE Search -->
                    <div id="search-toggle-block">
                        <div id="search">
                            <form role="search" id="searchform" action="/search" method="get" class="radius-xl">
                                <div class="input-group">
                                    <input class="form-control" value="" name="q" type="search" placeholder="Type to search"/>
                                    <span class="input-group-append"><button type="button" class="search-btn"><i class="fa fa-search"></i></button></span>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>

    </div>

</header>
