<div class="slider-outer">

  <div id="welcome_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="goodnews-header" data-source="gallery" style="background:#eeeeee;padding:0px;">
      <div id="welcome_two" class="rev_slider fullscreenbanner" style="display:none;" data-version="5.4.3.1">
          <ul>

              <!-- SLIDE 1 -->
              <li data-index="rs-901"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-thumb="assets/images/main-slider/slider2/slide1.jpg"
              data-rotate="0"
              data-fstransition="fade"
              data-fsmasterspeed="300"
              data-fsslotamount="7"
              data-saveperformance="off"
              data-title="Slide Title"
              data-param1="Additional Text"
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description="">
                  <!-- MAIN IMAGE -->
                  <img src="assets/images/main-slider/slider2/slide1.jpg"  alt=""  data-lazyload="assets/images/main-slider/slider2/slide1.jpg" data-bgposition="center center"
                  data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
                  <!-- LAYERS -->

                  <!-- LAYER NR. 1 [ for overlay ] -->
                  <div class="tp-caption tp-shape tp-shapewrapper "
                  id="slide-901-layer-0"
                  data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                  data-width="full"
                  data-height="full"
                  data-whitespace="nowrap"
                  data-type="shape"
                  data-basealign="slide"
                  data-responsive_offset="off"
                  data-responsive="off"
                  data-frames='[
                  {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 1;background-color:rgba(0, 0, 0, 0.1);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                  </div>

                  <!-- LAYER NR. 2 [ Black Box ] -->
                  <div class="tp-caption   tp-resizeme site-text-primary"
                  id="slide-901-layer-2"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','34','30']"
                  data-y="['top','top','top','top']" data-voffset="['80','180','180','180']"
                  data-fontsize="['48','48','48','34']"
                  data-lineheight="['48','48','48','48']"
                  data-width="['700','700','96%','96%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[10,10,10,4]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[10,10,10,4]"
                  data-paddingleft="[30,30,30,20]"

                  style="z-index: 13;
                  white-space: normal;
                  font-weight: 300;
                  font-family: 'Teko', sans-serif; border-left:8px solid #00173c"></div>

                  <!-- LAYER NR. 3 [ for title ] -->
                  <div class="tp-caption   tp-resizeme"
                  id="slide-901-layer-3"
                  data-x="['left','left','left','left']" data-hoffset="[100','120','30','30']"
                  data-y="['top','top','top','top']" data-voffset="['180','280','280','260']"
                  data-fontsize="['120','72','72','38']"
                  data-lineheight="['100','66','48','38']"
                  data-width="['700','700','96%','96%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[5,5,5,5]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 13;
                  white-space: normal;
                  font-weight: 300;
                  color:#00173c;
                  border-width:0px; font-family: 'Teko', sans-serif; text-transform:uppercase;"></div>

                  <!-- LAYER NR. 4 [ for paragraph] -->
                  <div class="tp-caption  tp-resizeme"
                  id="slide-901-layer-4"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','30','30']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['70','40','40','-10']"
                  data-fontsize="['20','20','20','16']"
                  data-lineheight="['30','30','30','22']"
                  data-width="['600','600','90%','90%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 13;
                  font-weight: 500;
                  color:#00173c;
                  border-width:0px;font-family: 'Poppins', sans-serif;">
                  <!-- In the world of renewable energy we cast quite a shadow. innovative products and services... -->
                  </div>

                  <!-- LAYER NR. 5 [ for see all service botton ] -->
                  <div class="tp-caption tp-resizeme"
                  id="slide-901-layer-5"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','30','30']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','80']"
                  data-lineheight="['none','none','none','none']"
                  data-width="['200','200','200','200']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13; text-transform:uppercase;">
                  <!-- <a routerLink="/other/contact1" class="site-button site-btn-effect">Contact Us</a> -->
                  </div>


                  <!-- LAYER NR. 6 [ VIDEO ICON ] -->
                  <div class="tp-caption"
                  id="slide-901-layer-6"
                  data-x="['left','left','left','left']" data-hoffset="['320','350','250','250']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','80']"
                  data-lineheight="['0','0','0','0']"
                  data-width="['100','100','100','100']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13;">
                      <!-- <a href="https://www.youtube.com/watch?v=fgExvIUYg5w" class="mfp-video play-now">
                          <i class="icon fa fa-play"></i>
                          <span class="ripple"></span>
                      </a> -->
                  </div>

                  <!-- LAYER NR. 7 [ ABOUT US ] -->
                  <div class="tp-caption"
                  id="slide-901-layer-7"
                  data-x="['left','left','left','left']" data-hoffset="['420','500','350','0']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','130']"
                  data-lineheight="['none','none','none','none']"
                  data-width="['100','100','100','100']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['right','right','right','right']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13;">
                      <!-- <a routerLink="/about/about1" class="site-button-link">About Us</a> -->
                  </div>

              </li>

              <!-- SLIDE 2 -->
              <li data-index="rs-902"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-thumb="assets/images/main-slider/slider2/slide3.jpg"
              data-rotate="0"
              data-fstransition="fade"
              data-fsmasterspeed="300"
              data-fsslotamount="7"
              data-saveperformance="off"
              data-title="Slide Title"
              data-param1="Additional Text"
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description="">
                  <!-- MAIN IMAGE -->
                  <img src="assets/images/main-slider/slider2/slide3.jpg"  alt=""  data-lazyload="assets/images/main-slider/slider2/slide3.jpg" data-bgposition="center center"
                  data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
                  <!-- LAYERS -->

                  <!-- LAYER NR. 1 [ for overlay ] -->
                  <div class="tp-caption tp-shape tp-shapewrapper "
                  id="slide-902-layer-0"
                  data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                  data-width="full"
                  data-height="full"
                  data-whitespace="nowrap"
                  data-type="shape"
                  data-basealign="slide"
                  data-responsive_offset="off"
                  data-responsive="off"
                  data-frames='[
                  {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 1;background-color:rgba(0, 0, 0, 0.1);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                  </div>

                  <!-- LAYER NR. 2 [ Black Box ] -->
                  <div class="tp-caption   tp-resizeme site-text-primary"
                  id="slide-902-layer-2"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','34','30']"
                  data-y="['top','top','top','top']" data-voffset="['80','180','180','180']"
                  data-fontsize="['48','48','48','34']"
                  data-lineheight="['48','48','48','48']"
                  data-width="['700','700','96%','96%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[10,10,10,4]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[10,10,10,4]"
                  data-paddingleft="[30,30,30,20]"

                  style="z-index: 13;
                  white-space: normal;
                  font-weight: 300;
                  font-family: 'Teko', sans-serif; border-left:8px solid #00173c"></div>

                  <!-- LAYER NR. 3 [ for title ] -->
                  <div class="tp-caption   tp-resizeme"
                  id="slide-902-layer-3"
                  data-x="['left','left','left','left']" data-hoffset="[100','120','30','30']"
                  data-y="['top','top','top','top']" data-voffset="['180','280','280','260']"
                  data-fontsize="['120','72','72','38']"
                  data-lineheight="['100','66','48','38']"
                  data-width="['700','700','96%','96%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[5,5,5,5]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 13;
                  white-space: normal;
                  font-weight: 300;
                  color:#00173c;
                  border-width:0px; font-family: 'Teko', sans-serif; text-transform:uppercase;"></div>

                  <!-- LAYER NR. 4 [ for paragraph] -->
                  <div class="tp-caption  tp-resizeme"
                  id="slide-902-layer-4"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','30','30']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['70','40','40','-10']"
                  data-fontsize="['20','20','20','16']"
                  data-lineheight="['30','30','30','22']"
                  data-width="['600','600','90%','90%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 13;
                  font-weight: 500;
                  color:#00173c;
                  border-width:0px;font-family: 'Poppins', sans-serif;">
                  <!-- In the world of renewable energy we cast quite a shadow. innovative products and services... -->
                  </div>

                  <!-- LAYER NR. 5 [ for see all service botton ] -->
                  <div class="tp-caption tp-resizeme"
                  id="slide-902-layer-5"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','30','30']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','80']"
                  data-lineheight="['none','none','none','none']"
                  data-width="['200','200','200','200']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13; text-transform:uppercase;">
                  <!-- <a routerLink="/other/contact1" class="site-button site-btn-effect">Contact Us</a> -->
                  </div>


                  <!-- LAYER NR. 6 [ VIDEO ICON ] -->
                  <div class="tp-caption"
                  id="slide-902-layer-6"
                  data-x="['left','left','left','left']" data-hoffset="['320','350','250','250']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','80']"
                  data-lineheight="['0','0','0','0']"
                  data-width="['100','100','100','100']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13;">
                      <!-- <a href="https://www.youtube.com/watch?v=fgExvIUYg5w" class="mfp-video play-now">
                          <i class="icon fa fa-play"></i>
                          <span class="ripple"></span>
                      </a> -->
                  </div>

                  <!-- LAYER NR. 7 [ ABOUT US ] -->
                  <div class="tp-caption"
                  id="slide-902-layer-7"
                  data-x="['left','left','left','left']" data-hoffset="['420','500','350','0']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','130']"
                  data-lineheight="['none','none','none','none']"
                  data-width="['100','100','100','100']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['right','right','right','right']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13;">
                      <!-- <a routerLink="/about/about1" class="site-button-link">About Us</a> -->
                  </div>

              </li>

              <!-- SLIDE 3 -->
              <li data-index="rs-903"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-thumb="assets/images/main-slider/slider2/premios-primavera.png"
              data-rotate="0"
              data-fstransition="fade"
              data-fsmasterspeed="300"
              data-fsslotamount="7"
              data-saveperformance="off"
              data-title="Slide Title"
              data-param1="Additional Text"
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description="">
                  <!-- MAIN IMAGE -->
                  <img src="assets/images/main-slider/slider2/premios-primavera.png"  alt=""  data-lazyload="assets/images/main-slider/slider2/premios-primavera.png" data-bgposition="center center"
                  data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
                  <!-- LAYERS -->

                  <!-- LAYER NR. 1 [ for overlay ] -->
                  <div class="tp-caption tp-shape tp-shapewrapper "
                  id="slide-902-layer-0"
                  data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                  data-width="full"
                  data-height="full"
                  data-whitespace="nowrap"
                  data-type="shape"
                  data-basealign="slide"
                  data-responsive_offset="off"
                  data-responsive="off"
                  data-frames='[
                  {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 1;background-color:rgba(0, 0, 0, 0.1);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                  </div>

                  <!-- LAYER NR. 2 [ Black Box ] -->
                  <div class="tp-caption   tp-resizeme site-text-primary"
                  id="slide-902-layer-2"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','34','30']"
                  data-y="['top','top','top','top']" data-voffset="['80','180','180','180']"
                  data-fontsize="['48','48','48','34']"
                  data-lineheight="['48','48','48','48']"
                  data-width="['700','700','96%','96%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[10,10,10,4]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[10,10,10,4]"
                  data-paddingleft="[30,30,30,20]"

                  style="z-index: 13;
                  white-space: normal;
                  font-weight: 300;
                  font-family: 'Teko', sans-serif; border-left:8px solid #00173c"></div>

                  <!-- LAYER NR. 3 [ for title ] -->
                  <div class="tp-caption   tp-resizeme"
                  id="slide-902-layer-3"
                  data-x="['left','left','left','left']" data-hoffset="[100','120','30','30']"
                  data-y="['top','top','top','top']" data-voffset="['180','280','280','260']"
                  data-fontsize="['120','72','72','38']"
                  data-lineheight="['100','66','48','38']"
                  data-width="['700','700','96%','96%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[5,5,5,5]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 13;
                  white-space: normal;
                  font-weight: 300;
                  color:#00173c;
                  border-width:0px; font-family: 'Teko', sans-serif; text-transform:uppercase;"></div>

                  <!-- LAYER NR. 4 [ for paragraph] -->
                  <div class="tp-caption  tp-resizeme"
                  id="slide-902-layer-4"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','30','30']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['70','40','40','-10']"
                  data-fontsize="['20','20','20','16']"
                  data-lineheight="['30','30','30','22']"
                  data-width="['600','600','90%','90%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 13;
                  font-weight: 500;
                  color:#00173c;
                  border-width:0px;font-family: 'Poppins', sans-serif;">
                  <!-- In the world of renewable energy we cast quite a shadow. innovative products and services... -->
                  </div>

                  <!-- LAYER NR. 5 [ for see all service botton ] -->
                  <div class="tp-caption tp-resizeme"
                  id="slide-902-layer-5"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','30','30']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','80']"
                  data-lineheight="['none','none','none','none']"
                  data-width="['200','200','200','200']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13; text-transform:uppercase;">
                  <!-- <a routerLink="/other/contact1" class="site-button site-btn-effect">Contact Us</a> -->
                  </div>


                  <!-- LAYER NR. 6 [ VIDEO ICON ] -->
                  <div class="tp-caption"
                  id="slide-902-layer-6"
                  data-x="['left','left','left','left']" data-hoffset="['320','350','250','250']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','80']"
                  data-lineheight="['0','0','0','0']"
                  data-width="['100','100','100','100']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13;">
                      <!-- <a href="https://www.youtube.com/watch?v=fgExvIUYg5w" class="mfp-video play-now">
                          <i class="icon fa fa-play"></i>
                          <span class="ripple"></span>
                      </a> -->
                  </div>

                  <!-- LAYER NR. 7 [ ABOUT US ] -->
                  <div class="tp-caption"
                  id="slide-902-layer-7"
                  data-x="['left','left','left','left']" data-hoffset="['420','500','350','0']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','130']"
                  data-lineheight="['none','none','none','none']"
                  data-width="['100','100','100','100']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['right','right','right','right']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13;">
                      <!-- <a routerLink="/about/about1" class="site-button-link">About Us</a> -->
                  </div>

              </li>
              <!-- SLIDE 4 -->
              <li data-index="rs-904"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-thumb="assets/images/main-slider/slider2/busqueda13.jpeg"
              data-rotate="0"
              data-fstransition="fade"
              data-fsmasterspeed="300"
              data-fsslotamount="7"
              data-saveperformance="off"
              data-title="Slide Title"
              data-param1="Additional Text"
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description="">
                  <!-- MAIN IMAGE -->
                  <img src="assets/images/main-slider/slider2/busqueda13.jpeg"  alt=""  data-lazyload="assets/images/main-slider/slider2/busqueda13.jpeg" data-bgposition="center center"
                  data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
                  <!-- LAYERS -->

                  <!-- LAYER NR. 1 [ for overlay ] -->
                  <div class="tp-caption tp-shape tp-shapewrapper "
                  id="slide-902-layer-0"
                  data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                  data-width="full"
                  data-height="full"
                  data-whitespace="nowrap"
                  data-type="shape"
                  data-basealign="slide"
                  data-responsive_offset="off"
                  data-responsive="off"
                  data-frames='[
                  {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 1;background-color:rgba(0, 0, 0, 0.1);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                  </div>

                  <!-- LAYER NR. 2 [ Black Box ] -->
                  <div class="tp-caption   tp-resizeme site-text-primary"
                  id="slide-902-layer-2"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','34','30']"
                  data-y="['top','top','top','top']" data-voffset="['80','180','180','180']"
                  data-fontsize="['48','48','48','34']"
                  data-lineheight="['48','48','48','48']"
                  data-width="['700','700','96%','96%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[10,10,10,4]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[10,10,10,4]"
                  data-paddingleft="[30,30,30,20]"

                  style="z-index: 13;
                  white-space: normal;
                  font-weight: 300;
                  font-family: 'Teko', sans-serif; border-left:8px solid #00173c"></div>

                  <!-- LAYER NR. 3 [ for title ] -->
                  <div class="tp-caption   tp-resizeme"
                  id="slide-902-layer-3"
                  data-x="['left','left','left','left']" data-hoffset="[100','120','30','30']"
                  data-y="['top','top','top','top']" data-voffset="['180','280','280','260']"
                  data-fontsize="['120','72','72','38']"
                  data-lineheight="['100','66','48','38']"
                  data-width="['700','700','96%','96%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[5,5,5,5]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 13;
                  white-space: normal;
                  font-weight: 300;
                  color:#00173c;
                  border-width:0px; font-family: 'Teko', sans-serif; text-transform:uppercase;"></div>

                  <!-- LAYER NR. 4 [ for paragraph] -->
                  <div class="tp-caption  tp-resizeme"
                  id="slide-902-layer-4"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','30','30']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['70','40','40','-10']"
                  data-fontsize="['20','20','20','16']"
                  data-lineheight="['30','30','30','22']"
                  data-width="['600','600','90%','90%']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index: 13;
                  font-weight: 500;
                  color:#00173c;
                  border-width:0px;font-family: 'Poppins', sans-serif;">
                  <!-- In the world of renewable energy we cast quite a shadow. innovative products and services... -->
                  </div>

                  <!-- LAYER NR. 5 [ for see all service botton ] -->
                  <div class="tp-caption tp-resizeme"
                  id="slide-902-layer-5"
                  data-x="['left','left','left','left']" data-hoffset="['100','120','30','30']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','80']"
                  data-lineheight="['none','none','none','none']"
                  data-width="['200','200','200','200']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13; text-transform:uppercase;">
                  <!-- <a routerLink="/other/contact1" class="site-button site-btn-effect">Contact Us</a> -->
                  </div>


                  <!-- LAYER NR. 6 [ VIDEO ICON ] -->
                  <div class="tp-caption"
                  id="slide-902-layer-6"
                  data-x="['left','left','left','left']" data-hoffset="['320','350','250','250']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','80']"
                  data-lineheight="['0','0','0','0']"
                  data-width="['100','100','100','100']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['center','center','center','center']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13;">
                      <!-- <a href="https://www.youtube.com/watch?v=fgExvIUYg5w" class="mfp-video play-now">
                          <i class="icon fa fa-play"></i>
                          <span class="ripple"></span>
                      </a> -->
                  </div>

                  <!-- LAYER NR. 7 [ ABOUT US ] -->
                  <div class="tp-caption"
                  id="slide-902-layer-7"
                  data-x="['left','left','left','left']" data-hoffset="['420','500','350','0']"
                  data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','130']"
                  data-lineheight="['none','none','none','none']"
                  data-width="['100','100','100','100']"
                  data-height="['none','none','none','none']"
                  data-whitespace="['normal','normal','normal','normal']"

                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[
                  {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                  {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                  ]'
                  data-textAlign="['right','right','right','right']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"

                  style="z-index:13;">
                      <!-- <a routerLink="/about/about1" class="site-button-link">About Us</a> -->
                  </div>

              </li>

                 <!-- SLIDE 5 -->
                 <li data-index="rs-905"
                 data-transition="fade"
                 data-slotamount="default"
                 data-hideafterloop="0"
                 data-hideslideonmobile="off"
                 data-easein="default"
                 data-easeout="default"
                 data-masterspeed="default"
                 data-thumb="assets/images/main-slider/slider2/bg-cucaracha.jpg"
                 data-rotate="0"
                 data-fstransition="fade"
                 data-fsmasterspeed="300"
                 data-fsslotamount="7"
                 data-saveperformance="off"
                 data-title="Slide Title"
                 data-param1="Additional Text"
                 data-param2=""
                 data-param3=""
                 data-param4=""
                 data-param5=""
                 data-param6=""
                 data-param7=""
                 data-param8=""
                 data-param9=""
                 data-param10=""
                 data-description="">
                     <!-- MAIN IMAGE -->
                     <img src="assets/images/main-slider/slider2/bg-cucaracha.jpg"  alt=""  data-lazyload="assets/images/main-slider/slider2/bg-cucaracha.jpg" data-bgposition="center center"
                     data-bgfit="cover" data-bgparallax="4" class="rev-slidebg" data-no-retina>
                     <!-- LAYERS -->

                     <!-- LAYER NR. 1 [ for overlay ] -->
                     <div class="tp-caption tp-shape tp-shapewrapper "
                     id="slide-902-layer-0"
                     data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                     data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                     data-width="full"
                     data-height="full"
                     data-whitespace="nowrap"
                     data-type="shape"
                     data-basealign="slide"
                     data-responsive_offset="off"
                     data-responsive="off"
                     data-frames='[
                     {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                     {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                     ]'
                     data-textAlign="['left','left','left','left']"
                     data-paddingtop="[0,0,0,0]"
                     data-paddingright="[0,0,0,0]"
                     data-paddingbottom="[0,0,0,0]"
                     data-paddingleft="[0,0,0,0]"

                     style="z-index: 1;background-color:rgba(0, 0, 0, 0.1);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                     </div>

                     <!-- LAYER NR. 2 [ Black Box ] -->
                     <div class="tp-caption   tp-resizeme site-text-primary"
                     id="slide-902-layer-2"
                     data-x="['left','left','left','left']" data-hoffset="['100','120','34','30']"
                     data-y="['top','top','top','top']" data-voffset="['80','180','180','180']"
                     data-fontsize="['48','48','48','34']"
                     data-lineheight="['48','48','48','48']"
                     data-width="['700','700','96%','96%']"
                     data-height="['none','none','none','none']"
                     data-whitespace="['normal','normal','normal','normal']"

                     data-type="text"
                     data-responsive_offset="on"
                     data-frames='[
                     {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                     {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                     ]'
                     data-textAlign="['left','left','left','left']"
                     data-paddingtop="[10,10,10,4]"
                     data-paddingright="[0,0,0,0]"
                     data-paddingbottom="[10,10,10,4]"
                     data-paddingleft="[30,30,30,20]"

                     style="z-index: 13;
                     white-space: normal;
                     font-weight: 300;
                     font-family: 'Teko', sans-serif; border-left:8px solid #00173c"></div>

                     <!-- LAYER NR. 3 [ for title ] -->
                     <div class="tp-caption   tp-resizeme"
                     id="slide-902-layer-3"
                     data-x="['left','left','left','left']" data-hoffset="[100','120','30','30']"
                     data-y="['top','top','top','top']" data-voffset="['180','280','280','260']"
                     data-fontsize="['120','72','72','38']"
                     data-lineheight="['100','66','48','38']"
                     data-width="['700','700','96%','96%']"
                     data-height="['none','none','none','none']"
                     data-whitespace="['normal','normal','normal','normal']"

                     data-type="text"
                     data-responsive_offset="on"
                     data-frames='[
                     {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                     {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                     ]'
                     data-textAlign="['left','left','left','left']"
                     data-paddingtop="[5,5,5,5]"
                     data-paddingright="[0,0,0,0]"
                     data-paddingbottom="[0,0,0,0]"
                     data-paddingleft="[0,0,0,0]"

                     style="z-index: 13;
                     white-space: normal;
                     font-weight: 300;
                     color:#00173c;
                     border-width:0px; font-family: 'Teko', sans-serif; text-transform:uppercase;"></div>

                     <!-- LAYER NR. 4 [ for paragraph] -->
                     <div class="tp-caption  tp-resizeme"
                     id="slide-902-layer-4"
                     data-x="['left','left','left','left']" data-hoffset="['100','120','30','30']"
                     data-y="['middle','middle','middle','middle']" data-voffset="['70','40','40','-10']"
                     data-fontsize="['20','20','20','16']"
                     data-lineheight="['30','30','30','22']"
                     data-width="['600','600','90%','90%']"
                     data-height="['none','none','none','none']"
                     data-whitespace="['normal','normal','normal','normal']"

                     data-type="text"
                     data-responsive_offset="on"
                     data-frames='[
                     {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                     {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                     ]'
                     data-textAlign="['left','left','left','left']"
                     data-paddingtop="[0,0,0,0]"
                     data-paddingright="[0,0,0,0]"
                     data-paddingbottom="[0,0,0,0]"
                     data-paddingleft="[0,0,0,0]"

                     style="z-index: 13;
                     font-weight: 500;
                     color:#00173c;
                     border-width:0px;font-family: 'Poppins', sans-serif;">
                     <!-- In the world of renewable energy we cast quite a shadow. innovative products and services... -->
                     </div>

                     <!-- LAYER NR. 5 [ for see all service botton ] -->
                     <div class="tp-caption tp-resizeme"
                     id="slide-902-layer-5"
                     data-x="['left','left','left','left']" data-hoffset="['100','120','30','30']"
                     data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','80']"
                     data-lineheight="['none','none','none','none']"
                     data-width="['200','200','200','200']"
                     data-height="['none','none','none','none']"
                     data-whitespace="['normal','normal','normal','normal']"

                     data-type="text"
                     data-responsive_offset="on"
                     data-frames='[
                     {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                     {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                     ]'
                     data-textAlign="['left','left','left','left']"
                     data-paddingtop="[0,0,0,0]"
                     data-paddingright="[0,0,0,0]"
                     data-paddingbottom="[0,0,0,0]"
                     data-paddingleft="[0,0,0,0]"

                     style="z-index:13; text-transform:uppercase;">
                     <!-- <a routerLink="/other/contact1" class="site-button site-btn-effect">Contact Us</a> -->
                     </div>


                     <!-- LAYER NR. 6 [ VIDEO ICON ] -->
                     <div class="tp-caption"
                     id="slide-902-layer-6"
                     data-x="['left','left','left','left']" data-hoffset="['320','350','250','250']"
                     data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','80']"
                     data-lineheight="['0','0','0','0']"
                     data-width="['100','100','100','100']"
                     data-height="['none','none','none','none']"
                     data-whitespace="['normal','normal','normal','normal']"

                     data-type="text"
                     data-responsive_offset="on"
                     data-frames='[
                     {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                     {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                     ]'
                     data-textAlign="['center','center','center','center']"
                     data-paddingtop="[0,0,0,0]"
                     data-paddingright="[0,0,0,0]"
                     data-paddingbottom="[0,0,0,0]"
                     data-paddingleft="[0,0,0,0]"

                     style="z-index:13;">
                         <!-- <a href="https://www.youtube.com/watch?v=fgExvIUYg5w" class="mfp-video play-now">
                             <i class="icon fa fa-play"></i>
                             <span class="ripple"></span>
                         </a> -->
                     </div>

                     <!-- LAYER NR. 7 [ ABOUT US ] -->
                     <div class="tp-caption"
                     id="slide-902-layer-7"
                     data-x="['left','left','left','left']" data-hoffset="['420','500','350','0']"
                     data-y="['middle','middle','middle','middle']" data-voffset="['160','130','130','130']"
                     data-lineheight="['none','none','none','none']"
                     data-width="['100','100','100','100']"
                     data-height="['none','none','none','none']"
                     data-whitespace="['normal','normal','normal','normal']"

                     data-type="text"
                     data-responsive_offset="on"
                     data-frames='[
                     {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                     {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                     ]'
                     data-textAlign="['right','right','right','right']"
                     data-paddingtop="[0,0,0,0]"
                     data-paddingright="[0,0,0,0]"
                     data-paddingbottom="[0,0,0,0]"
                     data-paddingleft="[0,0,0,0]"

                     style="z-index:13;">
                         <!-- <a routerLink="/about/about1" class="site-button-link">About Us</a> -->
                     </div>

                 </li>

          </ul>
          <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>
      </div>
  </div>

</div>
