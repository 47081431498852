<div class="page-wraper">


  <!-- HEADER START -->
  <!-- <app-section-header2></app-section-header2> -->
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">

      <!-- INNER PAGE BANNER -->
      <app-section-inner-banner [data]="banner"></app-section-inner-banner>
      <!-- INNER PAGE BANNER END -->

      <!-- GALLERY SECTION START -->
      <div class="section-full p-t80 p-b50 bg-white">
          <div class="container">
              <!-- PAGINATION START -->
              <div class="filter-wrap work-grid p-b30 text-center">
                  <ul class="masonry-filter link-style ">
                          <li class="active"><a data-filter="*" href="javascript:void(0);">All Cases</a></li>
                          <li><a data-filter=".cat-1" href="javascript:void(0);">Chemical</a></li>
                          <li><a data-filter=".cat-2" href="javascript:void(0);">Construction</a></li>
                          <li><a data-filter=".cat-3" href="javascript:void(0);">Industrial</a></li>
                          <li><a data-filter=".cat-4" href="javascript:void(0);">Oil & Gas</a></li>
                          <li><a data-filter=".cat-5" href="javascript:void(0);">Agriculture</a></li>
                  </ul>
              </div>
              <!-- PAGINATION END -->

              <!-- GALLERY CONTENT START -->
              <div class="masonry-wrap mfp-gallery  row clearfix d-flex justify-content-center flex-wrap">
                  <!-- COLUMNS 1 -->
                  <div *ngFor="let object of projects" class="masonry-item {{object.category}} col-lg-4 col-md-6 col-sm-6 m-b30">

                      <div class="project-img-effect-1">
                          <img src="{{object.image}}" alt="" />
                          <div class="wt-info">
                              <h3 class="wt-tilte m-b10 m-t0">{{object.title}}</h3>
                              <p>{{object.description}}</p>
                              <a routerLink="/projects/project-detail" class="site-button-link">Read More</a>
                          </div>

                          <a href="{{object.image}}" class="mfp-link"><i class="fa fa-search-plus"></i></a>
                      </div>

                  </div>

               </div>
              <!-- GALLERY CONTENT END -->
          </div>
      </div>
      <!-- GALLERY SECTION END -->

  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <!-- <app-section-footer1></app-section-footer1> -->
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>

