<div class="section-full video2-counter-section p-t80 p-b40  overlay-wraper bg-cover bg-no-repeat" style="background-image:url(assets/images/background/bg-video.jpg);">
    <div class="overlay-main opacity-09"></div>

            <div class="container">
                <!-- TITLE START-->
                <div class="section-head center wt-small-separator-outer text-center text-white">
                    <div class="wt-small-separator site-text-primary">
                        <div  class="sep-leaf-left"></div>
                        <div>{{data.title}}</div>
                        <div  class="sep-leaf-right"></div>
                    </div>
                    <h2>{{data.subtitle}}</h2>
                </div>
                <!-- TITLE END-->

                <div class="counter2-section-outer-top">
                        <div class="row justify-content-center">

                          <div class="row">
                            <div class="col-lg-12 quality-video">

                          <!-- Dominit RC�HD� video player version YR18- Copyright Dominican Internet Group� -->
                              <iframe
                                width="800px" height="700px" frameBorder="0" allowfullscreen allow-same-origin allow-scripts
                                src="https://dominicanplayers.com/video-player/1707/0">
                              </iframe>
                         <!-- Dominit RC�HD� video player version YR18- Copyright Dominican Internet Group� -->

                            </div>
                        </div> <!-- row -->


                        </div>
                </div>
        </div>
</div>
