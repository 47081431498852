

<app-section-header2></app-section-header2>

<router-outlet></router-outlet>

<app-section-footer2></app-section-footer2>


<!-- BUTTON TOP START -->
<button class="scroltop"><span class="fa fa-angle-up  relative" id="btn-vibrate"></span></button>

<!-- Get In Touch -->
<app-section-get-in-touch></app-section-get-in-touch>
