import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-home2',
  templateUrl: './page-home2.component.html',
  styleUrls: ['./page-home2.component.css']
})
export class PageHome2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  specifications = [
    {
      title: "Premios Primavera",
      description: "Premios Primavera es una entrega de premios para celebridades de habla hispana en las areas de cine, musica, deportes y moda.",
      icon: "flaticon-trophy",
      serial: "01",
      pagelink: "https://premiosprimavera.com/",
      img:"assets/images/background/premios_primavera.png"
    },
    {
      title: "Busqueda 13 Radio",
      description: "Tu radio en la web, con la mejor programacion de musica variada, programas y eventos. En vivo 24/7 desde New York City.",
      icon: "flaticon-magnifying-glass",
      serial: "02",
      pagelink: "http://www.busqueda13.com/",
      img:"assets/images/background/busqueda13_logoweb.jpg"
    },
    {
      title: "AL RITMO DE LA NOCHE TV",
      description: "con contenido informativo y de entretenimiento a traves de nuestros diferentes proyectos.",
      icon: "flaticon-history",
      serial: "03",
      pagelink: "https://alritmodelanoche.com/",
      img:"assets/images/background/cropped-ritmo.png"
    },
    {
      title: "Radio Cucaracha ",
      description: " La emisora del pueblo. Somos una emisora radicada en la ciudad de New York City,  en vivo via streaming desde la web, con la mejor programacion.",
      icon: "flaticon-history",
      serial: "04",
      pagelink: "http://radiocucaracha.com/",
      img:"assets/images/background/logo-radio-cucaracha.jpg"
    }
  ]



  caseStudies = {
    title: "Colome TV",
    subtitle: "Anunciantes",
    bgImage: "assets/images/background/bg-sponsor.jpg",
    cases: [
      {
        image: "assets/images/client-logo/galant.jpg",
        title: "GALANT GRAPHICS",
        subtitle: "specializes in many types of designs",
        url:"https://galantgraphics.com/"
      },
       {
        image: "assets/images/client-logo/iphone-conexion.jpg",
        title: "Iphone Conexion",
        subtitle: "Iphone Repair Specialist",
        url:"http://iphoneconexion.com/"
      },
      // {
      //   image: "assets/images/client-logo/LITTLE_LIGHT.jpg",
      //   title: "LITTLE LIGHT WOODWORKING ING",
      //   subtitle: "We like so much to work with any kind of wood",
      //   url:"https://www.instagram.com/littlelightwoodworking/"
      // },
      {
        image: "assets/images/client-logo/isidor.jpg",
        title: "ISIDOR LAW",
        subtitle: "Music & Entertainment Attorney in New York City",
        url:"https://www.isidorlaw.com/"
      },
       {

        image: "assets/images/client-logo/disponible373.png",
        title: "Espacio Disponible",
        subtitle: "Anunciese con Nosotros",
        url:""
      },
       {
        image: "assets/images/client-logo/disponible373.png",
        title: "Espacio Disponible",
        subtitle: "Anunciese con Nosotros",
        url:""
      },
      {
        image: "assets/images/client-logo/disponible373.png",
        title: "Espacio Disponible",
        subtitle: "Anunciese con Nosotros",
        url:""
      },
    ]
  }

  projects = {
    title: "Colome TV",
    subtitle: "Nuestra programacion y proyectos",
    bgImage: "assets/images/background/home-prog.jpeg",
    projects: [
      {
        category: "cat-1",
        image: "assets/images/project/square/1.jpg",
        title: "Oil & Gas",
        pagelink: "s-oilgas"
      },
      {
        category: "cat-2",
        image: "assets/images/project/square/2.jpg",
        title: "Automotive Manufacturing",
        pagelink: "s-automotive"
      },
      {
        category: "cat-2",
        image: "assets/images/project/square/3.jpg",
        title: "Construction",
        pagelink: "s-civil"
      },
      {
        category: "cat-4",
        image: "assets/images/project/square/4.jpg",
        title: "Industrial",
        pagelink: "s-mechanical"
      },
      {
        category: "cat-5",
        image: "assets/images/project/square/5.jpg",
        title: "Agriculture",
        pagelink: "s-agricultural"
      },
      {
        category: "cat-3",
        image: "assets/images/project/square/6.jpg",
        title: "Chemical",
        pagelink: "s-chemical"
      },
      {
        category: "cat-1",
        image: "assets/images/project/square/7.jpg",
        title: "Industrial",
        pagelink: "s-mechanical"
      }
    ]
  }

  awards = {
    title: "Premios Primavera",
    subtitle: "Algunos de los ganadores",
    bgImage: "assets/images/background/home-prog.jpeg",
    projects: [
      {
        category: "cat-1",
        image: "assets/images/premios-primavera/francis_mendez.jpeg",
        title: "Francis Mendez 2019",
        subtitle: "Gran Primavera",
        pagelink: "s-oilgas"
      },
      {
        category: "cat-2",
        image: "assets/images/premios-primavera/roberto_geronimo.jpeg",
        title: "Roberto Geronimo 2021",
        subtitle: "Gran Primavera",
        pagelink: "s-automotive"
      },
      {
        category: "cat-3",
        image: "assets/images/premios-primavera/frank_giron.jpeg",
        title: "Frank Giron 2022",
        subtitle: "Gran Primavera",
        pagelink: "s-civil"
      },
      {
        category: "cat-3",
        image: "assets/images/premiosprimavera/2022/LUIS_MIGUEL_DEL_AMARGUE_BACHATERO_NOMINADO_2022.jpg" ,
        title: "Luis Miguel del Amargue ",
        subtitle: "Bachatero Nominado 2022",
        pagelink: "s-civil"
      },
     {
        category: "cat-2",
        image: "assets/images/premiosprimavera/2021/CARLOS_MANUEL_EL_ZAFIRO_ARTISTA_INVITADO_2021.jpg",
        title: "Carlos Manuel El Zafiro",
        subtitle: "Artista Invitado 2021",
        pagelink: "s-automotive"
      },
      {
        category: "cat-1",
        image: "assets/images/premiosprimavera/2019/CEREMONIA_FINAL_2019.jpg",
        title: "Ceremonial Final",
        subtitle: "2019",
        pagelink: "s-chemical"
      },


      {
        category: "cat-5",
        image: "assets/images/premiosprimavera/ALRITMO01.jpg" ,
        title: "Al Ritmo de la Noche",
        // subtitle: "2019",
        pagelink: "s-chemical"
      },
      {
        category: "cat-5",
        image: "assets/images/premiosprimavera/509.jpg",
        title: "Al Ritmo de la Noche",
        // subtitle: "2019",
        pagelink: "s-chemical"
      },
      {
        category: "cat-5",
        image: "assets/images/premiosprimavera/767.jpg",
        title: "Al Ritmo de la Noche",
        // subtitle: "2019",
        pagelink: "s-chemical"
      },

      {
        category: "cat-4",
        image: "assets/images/premiosprimavera/ADRIANO-ESPAILLATY-GELMA-BATISTA-DARIO-COLOME.jpg",
        title: "Busqueda 13",
        // subtitle: "2019",
        pagelink: "s-chemical"
      },
      {
        category: "cat-4",
        image: "assets/images/premiosprimavera/busqueda13.jpeg",
        title: "Busqueda 13",
        // subtitle: "2019",
        pagelink: "s-chemical"
      },



      {
        category: "cat-4",
        image: "assets/images/premiosprimavera/busqueda13-2.jpeg",
        title: "Busqueda 13",
        // subtitle: "2019",
        pagelink: "s-chemical"
      },

      // {
      //   category: "cat-2",
      //   image: "assets/images/premios-primavera/2021/DARY_DARY.jpg",
      //   title: "Dary Dary",
      //   subtitle: "Productor Musical",
      //   pagelink: "s-automotive"
      // },

      // {
      //   category: "cat-1",
      //   image: "assets/images/premiosprimavera/2019/PREMIO_ESPECIAL_PARA_JOSEFA_CASTILLO_2019.jpeg",
      //   title: "Josefa Castillo",
      //   subtitle: "2019",
      //   pagelink: "s-mechanical"
      // },
      // {
      //   category: "cat-2",
      //   image: "assets/images/premiosprimavera/2021/INVITADO_2021.jpeg" ,
      //   title: "Invitados",
      //   subtitle: "2021",
      //   pagelink: "s-automotive"
      // },


      // {
      //   category: "cat-2",
      //   image: "assets/images/premiosprimavera/2021/NOMINADOS_2021.jpeg",
      //   title: "Nominados",
      //   subtitle: "2021",
      //   pagelink: "s-automotive"
      // },
      // {
      //   category: "cat-1",
      //   image: "assets/images/premiosprimavera/2019/PRESENTADORES_2019.JPG",
      //   title: "Presentadores",
      //   subtitle: "2019",
      //   pagelink: "s-chemical"
      // },
      // {
      //   category: "cat-2",
      //   image: "assets/images/premiosprimavera/2021/PRESENTADORES_2021.jpg",
      //   title: "Presentadores",
      //   subtitle: "2021",
      //   pagelink: "s-automotive"
      // },

    //   {
    //     category: "cat-2",
    //     image: "assets/images/premiosprimavera/2021/CEREMONIA_FINAL_2021.jpg",
    //     title: "Ceremonia Final",
    //     subtitle: "2021",
    //     pagelink: "s-automotive"
    //   },
      // {
      //   category: "cat-1",
      //   image: "assets/images/premiosprimavera/2019/PRESENTTADORES_2019.jpeg",
      //   title: "Presentadores",
      //   subtitle: "2019",
      //   pagelink: "s-chemical"
      // },
      // {
      //   category: "cat-2",
      //   image: "assets/images/premiosprimavera/2021/GRUPO_D_AHORA_ARTISTA_INVITADOS_2021.jpg",
      //   title: "Grupo de Ahora",
      //   subtitle: "2021",
      //   pagelink: "s-automotive"
      // },
      // {
      //   category: "cat-3",
      //   image: "assets/images/premiosprimavera/2022/ARTISTA_INVITADO_JENRRY_CASTRO_2022.jpg" ,
      //   title: "Jenry Castro 2022",
      //   subtitle: "Artista Invitado",
      //   pagelink: "s-civil"
      // },

      // {
      //   category: "cat-2",
      //   image: "assets/images/premiosprimavera/2021/INVITADOS_2021_3.jpg",
      //   title: "Invitados",
      //   subtitle: "2021",
      //   pagelink: "s-automotive"
      // },
      // {
      //   category: "cat-2",
      //   image: "assets/images/project/square/5.jpg",
      //   title: "Agriculture",
      //   pagelink: "s-agricultural"
      // },


      // {
      //   category: "cat-1",
      //   image: "assets/images/premios-primavera/JOSE_SABALA.jpeg",
      //   title: "Jose Sabala",
      //   subtitle: "2019",
      //   pagelink: "s-mechanical"
      // },
      // {
      //   category: "cat-3",
      //   image: "assets/images/premiosprimavera/2022/AMIGOS_2022.jpg" ,
      //   title: "Amigos",
      //   subtitle: "2022",
      //   pagelink: "s-civil"
      // },

    //   {
    //     category: "cat-3",
    //     image: "assets/images/premiosprimavera/2022/CEREMONIA_FINAL_2022.jpg" ,
    //     title: "Ceremonia Final",
    //     subtitle: "2022",
    //     pagelink: "s-civil"
    //   },
    //         {
    //     category: "cat-3",
    //     image: "assets/images/premiosprimavera/2022/KEINER_MIRANDA_PRODUTOR_TV_2022.jpg",
    //     title: "Keiner Miranda 2022",
    //     subtitle: "Productor TV",
    //     pagelink: "s-civil"
    //   },
    //  {
    //     category: "cat-3",
    //     image: "assets/images/premiosprimavera/2022/NOMINADOS_2022.jpg",
    //     title: "Nominados",
    //     subtitle: "2022",
    //     pagelink: "s-civil"
    //   },

      // {
      //   category: "cat-3",
      //   image: "assets/images/premiosprimavera/2022/NOMINADOS_2022_7.jpg",
      //   title: "Nominados",
      //   subtitle: "2022",
      //   pagelink: "s-civil"
      // }

    ]
  }


  about = {
    title: "Colome TV",
    subtitle: "",
    image1: "assets/images/colarge/tv-prod.png",
    image2: "assets/images/colarge/tv-prod2.jpg",
    since: "2001",
    description: "es un canal de televisión dirigido para los latinoamericanos, con una programación diferente, con un contenido variado, entretenimiento, entrevista, deportes, reportajes, inmigración, temas laborales que va de mano con toda la comunidad.",
    services: [
      {
        value: "Entretenimiento"
      },
      {
        value: "Farandula"
      },
      {
        value: "Noticias"
      }
    ]
  }

  video = {
    title: "La Mejor Programacion",
    subtitle: "Disfrute de nuestra Programacion",
    videolink: "https://www.youtube.com/watch?v=fgExvIUYg5w",
    solutions: [
      {
        count: "35",
        title: "Projects Completed"
      },
      {
        count: "1435",
        title: "Work Employed"
      },
      {
        count: "750",
        title: "Work facilities"
      },
      {
        count: "36",
        title: "Winning Awards"
      }
    ],
    description: {
      title: "The Best Solution For all industries",
      subtitle: "Seving an impressive list of long-term clients with experience and expertise in industries.",
      quote: "Many of Our SELF registered employees are requested an main preferred temporary staff when all service",
      quoteby: "Farnandoz Biki, CEO"
    }
  }

  clients = {
    bgcolor: "bg-white",
    clients: [
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png",
      "assets/images/client-logo/disponible320.png"
    ]
  }

  testimonials = {
    title: "What our client say",
    subtitle: "Happy WIth Customers & Clients",
    quotesTitle: "Testimonial",
    quotes: [
      {
        image: "assets/images/testimonials/pic1.jpg",
        quote: "This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!",
        name: "Malcolm Franzcrip",
        post: "Contractor"
      },
      {
        image: "assets/images/testimonials/pic2.jpg",
        quote: "This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!",
        name: "Malcolm Franzcrip",
        post: "Contractor"
      },
      {
        image: "assets/images/testimonials/pic3.jpg",
        quote: "This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!",
        name: "Malcolm Franzcrip",
        post: "Contractor"
      },
      {
        image: "assets/images/testimonials/pic4.jpg",
        quote: "This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!",
        name: "Malcolm Franzcrip",
        post: "Contractor"
      }
    ]
  }

  team = {
    title: "Our Experts",
    subtitle: "We will serve you with the best of our capacity by expert team",
    bgImage: "assets/images/background/bg-map.png",
    members: [
      {
        image: "assets/images/team/pic1.jpg",
        name: "Malcolm Franzcrip",
        post: "Contractor"
      },
      {
        image: "assets/images/team/pic2.jpg",
        name: "Froster Collings",
        post: "Contractor"
      },
      {
        image: "assets/images/team/pic3.jpg",
        name: "Melena Marshall",
        post: "Contractor"
      }
    ]
  }

  blogs = {
    title: "Latest Articles Updated Daily",
    subtitle: "We Are Here To Learn You More From Blog",
    featured: {
      image: "assets/images/blog/default/thum1.jpg",
      field: "Materials",
      date: "July 28, 2021",
      comments: "24",
      title: "Technology is important for your business, you use technology makes"
    },
    others: [
      {
        field: "Materials",
        date: "July 28, 2021",
        comments: "24",
        title: "Explain to you why they work and what makes people click them"
      },
      {
        field: "Oil & Gas",
        date: "July 28, 2021",
        comments: "24",
        title: "Explain to you why they work and what makes people click them"
      },
      {
        field: "Chemical",
        date: "July 28, 2021",
        comments: "24",
        title: "Explain to you why they work and what makes people click them"
      }
    ]
  }

  selfIntro = {
    image: "assets/images/self-pic.png",
    bgImage: "assets/images/background/bg-self.jpg",
    bgClass: "bg-gray",
    bgCover: "",
    opacity: "opacity-09",
    imageClass: "self-intro-pic-block"
  }
}
