<div class="page-wraper">


    <!-- HEADER START -->
    <!-- <app-section-header2></app-section-header2> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- ALL SERVICES START -->
        <app-section-services11 [data]="services"></app-section-services11>
        <!-- ALL SERVICES SECTION END -->

        <!-- OUR PLAN US START -->
        <app-section-plans3 [data]="plans"></app-section-plans3>
        <!-- OUR PLAN US SECTION END -->

        <!-- TESTIMONIAL SECTION START -->
        <app-section-testimonials3 [data]="testimonials" whiteBox="1"></app-section-testimonials3>
        <!-- TESTIMONIAL SECTION END -->

         <!-- VISION SECTION START -->
         <app-section-vision1 [data]="vision"></app-section-vision1>
        <!-- VISION SECTION END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
