<div class="page-wraper">


    <!-- HEADER START -->
    <!-- <app-section-header2></app-section-header2> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- Error SECTION START -->
        <div class="section-full p-t80 p-b80 bg-gray">
            <div class="container">
                <div class="section-content">

                    <div class="page-notfound error-405 text-center">

                        <h3>Http Error </h3>
                        <strong class="site-text-primary title-style-2">405</strong>
                        <h4 class="title-style-2">Method Not Allowed</h4>
                        <p>The page you are looking for cannot be displayed beacause an invalid method(HTTP web) is being used.</p>

                        <a routerLink="/index" class="site-button site-btn-effect">GO TO HOME</a>

                    </div>

                </div>
            </div>
        </div>
        <!-- Error  SECTION END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer1></app-section-footer1>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
